import { FunctionComponent } from 'react'
import { FeaturePageTemplate, TFeatureItem } from './_featurePageTemplate'
import longListImg from './imgs/classifcation/longList.gif'
import searchGif from './imgs/classifcation/search.gif'
import easyPng from './imgs/classifcation/sentiment.png'
const items: TFeatureItem[] = [
  {
    title: 'Label 10X Faster with guided search',
    description:
      'Use your common sense and knowedlge with full text search to label fast',
    img: searchGif,
  },
  {
    title: 'Long Label Lists and hierarchies',
    description:
      'Have hundreds or thousands of classes ? No problem, use our searchable drop down list to find the right class instantly',
    img: longListImg,
  },
  {
    title: 'Easy To Use',
    description:
      'Getting your team productive fast is our priority. LightTag makes document classification dead simple',
    img: easyPng,
  },
]
const DocumentClassifctation: FunctionComponent = () => (
  <FeaturePageTemplate
    header={'Document Classification Annotation'}
    subheader={'Label Data For Document Classification Fast'}
    featureItems={items}
    googleDescription={'Simple & fast labeling for all your documents'}
    googleKeywords={[
      'classification',
      'NLP classification',
      'document classification',
      'document categorization',
      'text annotation',
      'text classification',
    ]}
  />
)
export default DocumentClassifctation
